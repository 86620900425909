<template>
    <div class="coupon-box" id="coupon-box">
        <title-nav :title="'领取卡券'"/>
        <div class="coupon-list" v-for="(coupon, i) in couponList" :key="i">
          <coupon-card
            :amount="coupon.discount_amount"
            :expirationDate="coupon.expiration_date"
            :code="coupon.coupon_code"
            @claimCoupon="claimCoupon"
            :showButton="true"
            :used="false"
            :claim="true"
            :couponType="coupon.type"
            :isClaimed="isClaimed"
            :title="coupon.title"/>
        </div>
        <div v-if="loading" class="loading">加载中...</div>
        <div v-if="couponList.length === 0 && !loading">
            <div class="none-list">
                <img src="../../assets/images/none.svg" alt="">
                <div class="none">没有可领取优惠券喔~</div>
            </div>
        </div>
    </div>
</template>

<script>
import { queryOneNoUidCoupon, couponsDistributionByCode } from '@/api/api'
import TitleNav from '../../components/nav/TitleNav.vue'
import CouponCard from '../../components/card/CouponCard.vue'
import moment from 'moment'
import {Toast} from 'vant'
import { getopenIdByCode } from '@/utils/index';

export default {
  name: 'ClaimCoupon',
  components: {
    TitleNav,
    CouponCard
  },
  mounted(){
    this.getData()
    getopenIdByCode()
  },
  data:()=>{
    return {
      couponList:[],
      loading: false,
      isClaimed: false,
      isLogin: localStorage.getItem('token') ? true : false,
    }
  },
  methods:{
    getData(){
      this.loading = true
      let type = this.$route.query.type ? this.$route.query.type : '1'
      let range = this.$route.query.range ? this.$route.query.range : '1'
      if(sessionStorage.getItem('coupon')){
        let coupon = JSON.parse(sessionStorage.getItem('coupon'))
        type = Number(coupon.type)
        range = Number(coupon.range)
      }
      queryOneNoUidCoupon({ type: type, range: range }).then(res => {
        this.loading = false
        res.data.data.forEach(item => {
          let temp = {}
          temp.discount_amount = item.discount_amount
          if(item.expiration_date === null){
            temp.expiration_date = '领取后查看'
          } else {
            temp.expiration_date = item.expiration_date
          }
          temp.coupon_code = item.coupon_code
          temp.uid = item.uid
          temp.title = item.title
          temp.type = item.type === 1 ? 'reduction' : 'discount'
          this.couponList.push(temp)
        })
      })
    },
    // 领取优惠券
    claimCoupon(coupon){
        if(this.isLogin){
            let uidTemp = this.isLogin ? JSON.parse(localStorage.getItem('userInfo'))[0].uid : ''
            // 默认有效期3天，url上可设置有效期天数，如： emer-nav.cn/claim-coupon?day=7&type=1&range=1
            let day = this.$route.query.day ? Number(this.$route.query.day) : 3
            let range = this.$route.query.range ? Number(this.$route.query.range) : 1
            if(sessionStorage.getItem('coupon')){
              let coupon = JSON.parse(sessionStorage.getItem('coupon'))
              day = String(coupon.day).length > 0 ? Number(coupon.day) : 3
              range = String(coupon.range).length > 0 ? Number(coupon.range) : 1
            }
            let expirationDate = moment().clone().add(day, 'days').format("YYYY-MM-DD HH:mm:ss")
            couponsDistributionByCode({ coupon_code: coupon.code, expiration_date: expirationDate, uid: uidTemp, range: range }).then((res)=>{
                // 跳转前清理
                if(sessionStorage.getItem('coupon')){
                  sessionStorage.removeItem('coupon')
                }
                if(res.data.msg_id === 'exceeds_the_maximum'){
                    Toast('数量超限，请前往卡包');
                    setTimeout(()=>{
                        this.$router.push('/coupon')
                    }, 3000)
                } else if(res.data.msg_id === 'claimed_today'){
                    Toast('今日已领取');
                    setTimeout(()=>{
                        this.$router.push('/coupon')
                    }, 3000)
                } else {
                    this.isClaimed = true
                    Toast('已领取');
                    setTimeout(()=>{
                        this.$router.push('/coupon')
                    }, 1000)
                }
            })
        } else {
            sessionStorage.setItem('fromPath', 'claimcoupon')
            let coupon = {}
            coupon.type = this.$route.query.type ? this.$route.query.type : '1'
            coupon.range = this.$route.query.range ? this.$route.query.range : '1'
            coupon.day = this.$route.query.day ? this.$route.query.day : '3'
            sessionStorage.setItem('coupon', JSON.stringify(coupon))
            this.$router.push({ name:'Login', params:{ text: '领卡券' }})
        }
    },
  },
  activated(){

  }
}
</script>

<style scoped lang="scss">
.coupon-box {
    height: 100%;
    .coupon-list {
      margin: 15px;
    }
}
.none-list {
    margin: 60px auto 20px;
    width: 100%;
    max-width: 625px;
    .none {
        margin: 10px 0 0;
        color: #969699;
    }
    img {
        min-height: 142px;
    }
}
.loading {
    color: #969699;
    margin: 0px auto 20px;
    font-size: 14px;
}
.bottom-more {
  background: #fff;
  color: #000;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  height: 50px;
  line-height: 50px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}
</style>